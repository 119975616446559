import img from "../assets/newImg/personalAccount/simple-logo.png";

interface BrandTheme {
    brandLogo: string;
    brandLogoWidth: string;
    brandLogoHeight: string;
    brandLogoAlt: string;
}

export const brandConfig: any = {
    companyName: process.env.REACT_APP_COMPANY_NAME, // Company Full Name
    companyNameSimple: process.env.REACT_APP_COMPANY_NAME_SIMPLE, // Company Name without type (Example: Iberbanco LTD ---> Iberbanco)
    companyWebsite: process.env.REACT_APP_COMPANY_WEBSITE, // Company Website URL
    companyMainEmailAddress: process.env.REACT_APP_COMPANY_MAIN_EMAIL_ADDRESS, // Company Main Email Address
    companyInfoEmailAddress: process.env.REACT_APP_COMPANY_INFO_EMAIL_ADDRESS, // Company Info Email Address
    companyComplianceEmailAddress: process.env.REACT_APP_COMPANY_COMPLIANCE_EMAIL_ADDRESS, // Company Compliance Email Address
    companySupportEmailAddress: process.env.REACT_APP_COMPANY_SUPPORT_EMAIL_ADDRESS, // Company Support Email Address
    companyLegalEmailAddress: process.env.REACT_APP_COMPANY_LEGAL_EMAIL_ADDRESS // Company Legal Email Address
};

export const brandLinks: any = {
    facebook: process.env.REACT_APP_FACEBOOK_URL, // Company Facebook Address
    linkedin: process.env.REACT_APP_LINKEDIN_URL, // Company Linkedin Address
    whatsapp: process.env.REACT_APP_WHATSAPP_URL, // Company Whatsapp Address
};

export const brandTheme: BrandTheme = {
    brandLogo: img,
    brandLogoWidth: '50px',
    brandLogoHeight: '50px',
    brandLogoAlt: 'Brand Logo',
}
